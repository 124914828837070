import { Preset } from '../types/types';

export const isCalendarPage = (preset: Preset) =>
  preset === Preset.CALENDAR_PAGE;

export const isWeeklyTimeTableWidget = (preset: Preset) =>
  preset === Preset.WEEKLY_TIMETABLE_WIDGET;

export const isCalendarWidget = (preset: Preset) =>
  preset === Preset.CALENDAR_WIDGET;
