import { Plan } from '@wix/ambassador-checkout-server/types';
import _ from 'lodash';
import {
  PricingPlan,
  ReservedLocationIds,
  Service,
  StaffMember,
} from '@wix/bookings-uou-types';
import { CalendarState } from '../../components/BookingCalendar/controller';
import { CalendarStatus } from '../../components/BookingCalendar/ViewModel/widgetViewModel/widgetViewModel';
import { FilterTypes } from '../../components/BookingCalendar/ViewModel/filterViewModel/filterViewModel';
import {
  BookingsQueryParams,
  WixOOISDKAdapter,
} from '@wix/bookings-adapter-ooi-wix-sdk';
import { EmptyStateType } from '../../components/BookingCalendar/ViewModel/emptyStateViewModel/emptyStateViewModel';
import { ServiceOptionsAndVariants } from '@wix/ambassador-bookings-catalog-v1-service-options-and-variants/types';
import { Preset, Optional, SlotsStatus } from '../../types/types';
import { getValidPurchasedPricingPlansForService } from '../pricingPlans/pricingPlans';
import { Balance } from '@wix/ambassador-pricing-plan-benefits-server/types';
import { Booking } from '@wix/bookings-checkout-api';
import { getAvailableServicesByPreset } from './getAvailableServicesByPreset';
import { extractQueryParamValues } from '../queryParams/queryParams';

export function createInitialState({
  availableServices,
  wixSdkAdapter,
  staffMembers,
  rescheduleBookingDetails,
  initialErrors,
  isAnonymousCancellationFlow,
  allPurchasedPricingPlans,
  isPricingPlanInstalled,
  isUserLoggedIn,
  serviceVariants,
  preset,
  isServiceListDropdownEnabled,
}: {
  availableServices: Service[];
  wixSdkAdapter: WixOOISDKAdapter;
  staffMembers?: StaffMember[];
  rescheduleBookingDetails?: Booking;
  initialErrors: EmptyStateType[];
  isAnonymousCancellationFlow?: boolean;
  allPurchasedPricingPlans?: Balance[];
  isPricingPlanInstalled: boolean;
  isUserLoggedIn: boolean;
  serviceVariants?: ServiceOptionsAndVariants;
  preset: Preset;
  isServiceListDropdownEnabled: boolean;
}): CalendarState {
  const locationFilterOptions = initializeLocationFilterOptions(wixSdkAdapter);
  const staffFilterOptions = initializeStaffMembersFilterOptions({
    staffMembers,
    wixSdkAdapter,
    preset,
    availableServices,
    isServiceListDropdownEnabled,
  });
  const purchasedPricingPlans = initializePurchasedPricingPlans({
    availableServices,
    allPurchasedPricingPlans,
    isPricingPlanInstalled,
    isUserLoggedIn,
  });
  const selectedDate = initializeSelectedDate(wixSdkAdapter);

  return {
    calendarStatus: CalendarStatus.INITIALIZING,
    slotsStatus: SlotsStatus.LOADING,
    availableServices: availableServices || [],
    selectedDate,
    selectedTimezone: undefined,
    selectedTime: undefined,
    availableSlots: undefined,
    selectableSlotsAtSelectedTime: undefined,
    selectedRange: undefined,
    availableSlotsPerDay: undefined,
    selectedBookingPreferences: [],
    calendarErrors: [],
    rescheduleBookingDetails,
    dialog: undefined,
    filterOptions: {
      [FilterTypes.SERVICE]: [],
      [FilterTypes.LOCATION]: locationFilterOptions,
      [FilterTypes.STAFF_MEMBER]: staffFilterOptions,
    },
    initialErrors,
    purchasedPricingPlans,
    isUserLoggedIn,
    selectedVariantsOptions: [],
    serviceVariants,
  };
}

const initializeLocationFilterOptions = (
  wixSdkAdapter: WixOOISDKAdapter,
): string[] => {
  const preSelectedLocations = extractQueryParamValues(
    wixSdkAdapter,
    BookingsQueryParams.LOCATION,
  );
  return preSelectedLocations.filter(
    (location) => location !== ReservedLocationIds.OTHER_LOCATIONS,
  );
};

const initializeStaffMembersFilterOptions = ({
  availableServices,
  wixSdkAdapter,
  preset,
  staffMembers,
  isServiceListDropdownEnabled,
}: {
  availableServices: Service[];
  wixSdkAdapter: WixOOISDKAdapter;
  preset: Preset;
  staffMembers?: StaffMember[];
  isServiceListDropdownEnabled: boolean;
}): string[] => {
  const staffFilterOptions: string[] = [];

  if (staffMembers) {
    const staffMemberId = staffMembers?.[0]?.id;
    if (staffMemberId) {
      const services = getAvailableServicesByPreset({
        availableServices,
        preset,
        isServiceListDropdownEnabled,
      });
      const isStaffMemberConnectedToService = services.some((service) =>
        _.find(service?.staffMembers, ({ id }) => id === staffMemberId),
      );
      if (isStaffMemberConnectedToService) {
        staffFilterOptions.push(staffMemberId);
      }
    }
  } else {
    const resourcesIds = extractQueryParamValues(
      wixSdkAdapter,
      BookingsQueryParams.RESOURCE,
    );
    if (resourcesIds.length) {
      const services = getAvailableServicesByPreset({
        availableServices,
        preset,
        isServiceListDropdownEnabled,
      });
      resourcesIds.forEach((resourceId) => {
        const isStaffMemberConnectedToService = services.some((service) =>
          _.find(service?.staffMembers, ({ id }) => id === resourceId),
        );
        if (isStaffMemberConnectedToService) {
          staffFilterOptions.push(resourceId);
        }
      });
    }
  }

  return staffFilterOptions;
};

const initializePurchasedPricingPlans = ({
  availableServices,
  allPurchasedPricingPlans,
  isPricingPlanInstalled,
  isUserLoggedIn,
}: {
  availableServices: Service[];
  allPurchasedPricingPlans?: Balance[];
  isPricingPlanInstalled: boolean;
  isUserLoggedIn: boolean;
}): Plan[] => {
  let purchasedPricingPlans: Plan[] = [];
  let servicePricingPlans: PricingPlan[] = [];

  if (isPricingPlanInstalled) {
    if (isUserLoggedIn) {
      servicePricingPlans =
        (availableServices.length &&
          availableServices[0].payment.pricingPlanInfo?.pricingPlans) ||
        [];
      purchasedPricingPlans = getValidPurchasedPricingPlansForService({
        servicePricingPlans,
        allPurchasedPricingPlans: allPurchasedPricingPlans || [],
      });
    }
  }
  return purchasedPricingPlans;
};

const initializeSelectedDate = (
  wixSdkAdapter: WixOOISDKAdapter,
): Optional<string> => {
  return wixSdkAdapter.getUrlQueryParamValue(BookingsQueryParams.DATE);
};
